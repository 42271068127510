import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import CardMedia from "@mui/material/CardMedia";
import Divider from "@mui/material/Divider";
import { CardContent } from "@mui/material";
import Card from "@mui/material/Card";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@mui/material/Tooltip";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import axios from "../utils/Axios"; //import axios config
import parse from "html-react-parser";
import LoadingOverlay from "react-loading-overlay";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const useStyles = makeStyles((theme) => ({
  TopCoursesCard: (hover) => ({
    width: "100%",
    height: "100%",
    background: "#FFF",
    borderRadius: "10px",
    boxShadow: "0px 0px 10px #00000029",
    margin: "5px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    transition: "0.3s",
    "&:hover": {
      boxShadow: "0px 0px 10px #00000069",
    },
  }),
}));

export default function IndustrialNewsCarousel() {

  const classes = useStyles();
  const history = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [popularnews, setPopularNews] = React.useState([]);

  React.useEffect(() => {
    popularList();
    window.scrollTo(0, 0);
  }, []);

  const popularList = () => {
    setLoading(true);
    axios(`Article/get_popular_news`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
          console.log(res.data.message);
        } else {
          setLoading(false);
          setPopularNews(res.data.data); //to set response data to state
        }
      })
      .catch((err) => console.log(err));
  };

  const ArticleView = (id) => {
    history(`/EducationGuideArticlesLists/${id}`);
    window.location.reload();
    window.scrollTo(0, 0);
  };

  return (
    <div style={{ width: "100%" }}>
      <Box
        className="caro_bg"
        sx={{ bgcolor: "#fff", pt: "48px", pb: "48px" }}
      >
        <Container maxWidth="xl">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              p: 1,
              overflow: "hidden",
            }}
          >
            <Box sx={{ p: 1 }}>
              <Typography variant="h5" gutterBottom component="div">
              Logistics News
              </Typography>
            </Box>
            <Box sx={{ p: 1 }}>
              <Divider
                color="white"
                sx={{ height: 28, m: 0.5 }}
                orientation="vertical"
              />
            </Box>

            <Box sx={{ p: 1, flexGrow: 1 }}>
              <Link to={`/ViewAllStories`}>
              <Typography
                className="cp"
                variant="body2"
                color="InactiveCaptionText"
                gutterBottom
                component="div"
              >
                View All Stories
              </Typography>
              </Link>
            </Box>
          </Box>
          <Box className="TopUniversities_carousel_bg">
            <Box width="100%">
            <LoadingOverlay
                active={loading}
                styles={{
                  overlay: (base) => ({
                    ...base,
                    background: "rgba(255, 255, 255, 1.9)",
                  }),
                  spinner: (base) => ({
                    ...base,
                    width: "75px",
                    "& svg circle": {
                      stroke: "#025b95",
                    },
                  }),
                }}
                spinner
                text="Loading ..."
              >
              <Carousel
                responsive={responsive}
                rightChevron={<KeyboardArrowRight />}
                leftChevron={<KeyboardArrowLeft />}
              >
                  {popularnews && popularnews.map((data, index) => (
                  <Box
                    p={2}
                    className="Opportunities_box"
                  >
                    <Link to={`/StoriesDetail/${data.id}`}>
                    <Card className={classes.TopCoursesCard}>
                      <CardMedia
                        component="img"
                        height="300"
                        src={data.images}
                        alt="title"
                      />
                      <Box p={1} className="cp">
                        <CardContent>
                          <Tooltip Tooltip title={data.title}>
                            <Typography
                              sx={{ mt: 1.5 }}
                              gutterBottom
                              textAlign="start"
                              variant="subtitle2"
                              fontWeight={600}
                              component="div"
                            >
                              {parse(data.title.substring(0, 35) + "...")}
                            </Typography>
                          </Tooltip>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              width: "fit-content",
                              bgcolor: "background.paper",
                              color: "text.secondary",
                              "& svg": {
                                m: 1.5,
                              },
                              "& hr": {
                                mx: 0.5,
                              },
                            }}
                          >
                            <Typography
                              variant="caption"
                              color="gray"
                              display="inline-block"
                            >
                              {data.created}{" "}
                            </Typography>
                            {/* <Box sx={{ px: 1 }}>
                              <Divider
                                color="white"
                                sx={{ height: 28, m: 0.5 }}
                                orientation="vertical"
                              />
                            </Box>
                            <Typography
                              variant="caption"
                              color="#572672"
                              display="inline-block"
                            >
                              {" "}
                              by Auther Name{" "}
                            </Typography> */}
                          </Box>
                          <Tooltip Tooltip title="description">
                            <Typography
                              className="text_bg"
                              variant="caption"
                              color="gray"
                              component="div"
                            >
                              {parse(data.description.substring(0, 60) + " ")}
                            </Typography>
                          </Tooltip>
                          <Typography
                            sx={{ cursor: "pointer" }}
                            variant="body2"
                          >
                            <Link
                              to={`/StoriesDetail/${data.id}`}
                            >
                              <Typography
                                display="block"
                                textAlign="right"
                                color="primary"
                                variant="p"
                              >
                                More
                              </Typography>
                            </Link>
                          </Typography>
                        </CardContent>
                      </Box>
                    </Card>
                    </Link>
                  </Box>
                ))}
              </Carousel>
              </LoadingOverlay>
            </Box>
          </Box>
        </Container>
      </Box>
    </div>
  );
}
