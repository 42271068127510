import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { Avatar } from "@mui/material";

const urls = [
  {
    value: 'https://saaehapp.com/', 
    label: 'Dashboard',
  },
  {
    value: 'https://aqar.saaehapp.com/',
    label: 'Aqar',
  },
  {
    value: 'https://medical.saaehapp.com/',
    label: 'Medical Tourism',
  },
  {
    value: 'https://taleem.saaehapp.com/',
    label: 'Taleem',
  },
  {
    value: 'https://izra.saaehapp.com/',
    label: 'Izra',
  },
  {
    value: 'https://amal.saaehapp.com/',
    label: 'Amal',
  },
  {
    value: 'https://isna.saaehapp.com/',
    label: 'Isna',
  },
  {
    value: 'https://wasel.saaehapp.com/',
    label: 'Wasel',
  },
  {
    value: 'https://amusement.saaehapp.com/',
    label: 'Amusement',
  },
  {
    value: 'https://sustainability.saaehapp.com/',
    label: 'Sustainability', 
    
  },
];

export default function SaaehListMenu() {
  const [url, setUrl] = React.useState("https://wasel.saaehapp.com/");

  const handleChange = (event) => {
    setUrl(event.target.value);
    window.location.href = event.target.value;
  };
  return (
    <div>
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1, width: "25ch" },
        }}
        noValidate
        autoComplete="off"
      >
        <div>
          <TextField
            size="small"
            id="outlined-select"
            select
            label="Select Sa`aeh"
            value={url}
            onChange={handleChange}
          >
            {urls.map((option) => (   
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </div>
  </Box>
    </div>
  )
}
