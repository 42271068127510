import React from "react";
import Footer from "../footer/Footer";
import AgroStories from "./AgroStories";
import Banner from "./Banner";
import CompaniesCarousel from "./CompaniesCarousel";
import DevelopersCarousel from "./DevelopersCarousel";
import ExploreNearby from "./ExploreNearby";
import IndividualsCarousel from "./IndividualsCarousel";
import IndustrialNewsCarousel from "./IndustrialNewsCarousel";
import OpportunitiesCarousel from "./OpportunitiesCarousel";
import CommuniteesCarousel from "./CommuniteesCarousel";
import OurMission from "./OurMission";
import StakeholdersCarousel from "./StakeholdersCarousel";

export default function Home() {
  return (
    <div>
      <Banner />
      <ExploreNearby />
      <OurMission />
      <CompaniesCarousel />
      <IndividualsCarousel />
      <IndustrialNewsCarousel />
      {/* <OpportunitiesCarousel /> 
      <AgroStories />*/}
      <DevelopersCarousel />
      {/* <StakeholdersCarousel /> */}
      <CommuniteesCarousel />
      <Footer />
    </div>
  );
}
